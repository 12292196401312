@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSansArabic';
  src: url('assets/fonts/NotoSansArabic_SemiCondensed-Medium.ttf');
  font-weight: 500;
}
